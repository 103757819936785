/* En tu archivo CSS */
@keyframes girar {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .cargando {
    animation: girar 2s linear infinite;
  }
  
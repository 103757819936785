/* Establece la perspectiva desde la cual se ve el elemento hijo 3D. Esto afecta la profundidad de los elementos 3D. */
.tarjeta-contenedor {
    perspective: 1000px;
    width: 75%; 

    margin: 0 auto; /* Añade esta línea */
  }
  
  /* Estiliza el contenedor de la tarjeta, definiendo su anchura, altura, el tipo de transición para las transformaciones, el estilo de transformación para preservar el 3D y el cursor. */
  .tarjeta {
    width: 100%; /* Define el ancho de la tarjeta como el 50% del contenedor padre. Ajusta según necesites. */
    height: 200px; /* Define la altura de la tarjeta. Puedes ajustar este valor según tu diseño. */
    transition: transform 0.6s; /* Suaviza la transformación aplicada al elemento durante 0.6 segundos. */
    transform-style: preserve-3d; /* Asegura que las transformaciones 3D se preserven para los hijos del elemento. */
    cursor: pointer; /* Cambia el cursor a una mano para indicar que el elemento es interactivo. */
  }
  
  /* Modifica el estado de la tarjeta cuando está volteada, aplicando una rotación en el eje Y de 180 grados para mostrar su dorso. */
  .tarjeta.volteada {
    transform: rotateY(180deg); /* Rota la tarjeta 180 grados a lo largo del eje Y, mostrando el dorso. */
  }
  
  /* Estilos comunes para el frente y el dorso de la tarjeta. */
  .frente, .dorso {
    position: absolute; /* Posiciona el frente y el dorso en el mismo lugar, uno sobre el otro. */
    width: 100%; /* El ancho del frente y el dorso ocupa el 100% de su contenedor padre (.tarjeta). */
    height: 100%; /* La altura del frente y el dorso ocupa el 100% de su contenedor padre (.tarjeta). */
    backface-visibility: hidden; /* Oculta la cara posterior de un elemento, evitando que se muestre cuando está girado. */
    border: 2px solid #ccc; /* Aplica un borde alrededor del frente y el dorso. Puedes ajustar el color y el grosor. */
  
    /* Centra el contenido dentro del frente y el dorso usando Flexbox. */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centra el contenido horizontalmente dentro del contenedor. */
    align-items: center; /* Centra el contenido verticalmente dentro del contenedor. */
    text-align: center; /* Asegura que el texto dentro del contenedor flex también esté centrado horizontalmente. */
  }
  
  /* Específico para el dorso de la tarjeta, inicia ya rotado 180 grados para que el efecto de volteo funcione correctamente. */
  .dorso {
    transform: rotateY(180deg); /* Rota el dorso de la tarjeta 180 grados a lo largo del eje Y desde el inicio. */
  }

  .esquina {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    font-size: 0.75em; /* O el tamaño que prefieras */
  }